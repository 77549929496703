.edit-product-heading {
  font-size: 22px;
  margin: 20px;
}
.product-card {
  margin: 20px 20px;
  width: 30%;
  height: 389px;
  border-radius: 9px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
}

.product-card:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.product-img {
  height: 180px;
  width: 180px;
  padding: 20px;
  margin: auto;
}

.product-description {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;
  font-size: 17px;
  height: 72px;
}

.product-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 20px;
}
.edit-btn {
  margin: 2px 20%;
  width: 60%;
  background: black;
  border-radius: 0;
}
.edit-btn:hover {
  background: transparent;
  color: black;
  border-color: black;
}

.edit-modal {
  background: rgba(0, 0, 0, 0.377);
  padding-left: 20px;
}

.edit-admin-loading {
  text-align: center;
  position: relative;
  margin: auto;
  margin-top: 150px;
}

.edit-admin-loading::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-left-color: black;
  border-right-color: black;
  top: -50px;
  left: 8px;
  animation: round 0.7s alternate infinite;
}
@keyframes round {
  0% {
    border-color: rgba(26, 24, 24, 0.1);
  }
  100% {
    border: 9px solid transparent;
    transform: rotate(359deg);
    border-color: rgb(235, 79, 58);
  }
}

@media screen and (max-width: 560px) {
  .product-card {
    margin: 20px auto;
    width: 18rem;
  }
}
