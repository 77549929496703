.foot {
  background-color: #CB96FE;
  
  color: white;
}

a{
  text-decoration: none;
  color: white;
}

.fa{
  font-size: 25px
}

p{
  font-size: larger;
}
