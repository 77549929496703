a {
  text-decoration: none;
  color: white;
}

.bg-black {
  background-color: black;
}

p {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  size: 20px;
}
.home__main {
  font-family:Arial, Helvetica, sans-serif;
  background-color: #894BCA;
  font-weight: bolder;
}

.header-custom {
  margin-left: 10vw;
  margin-top: -10vw;
}

.home__text {
  margin-top: -40vw;
  font-size: 3vw;
  padding: 8vw;
  width: 50%;
}

.home__text p {
  font-size: medium;
}

@media (min-width: 960px) and (max-width: 1299px) {
  .header-custom {
    width: 90%;
    
  }
}

@media screen and (max-width: 960px) {
  .home__text {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-top: 8vw;
    text-align: center;
    font-size: larger;
  }
  .header-custom {
    display: block;
    margin-left: -20vw;
    margin-top: 3vw;
  

  }

  .home__text  {
    font-size: 8vw;
    font-weight: 900;
  }
}

.headline {
  margin-top: 10vw;
  padding-top: 1.5rem;
}


.head-text{
margin-bottom: 10vw;
padding-bottom: 2rem;
margin-top: 2vw;
font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif

}
.shopBtn{
  text-align: left;
font: normal normal bold 23px/34px  ;
font-family:Arial, Helvetica, sans-serif;
letter-spacing: 0px;
color: #B034DB;
opacity: 1;
font-weight: bold;
border-bottom-left-radius: 50%;
border-top-right-radius: 50%;
}