.img__1 {
  height: 300px;
  max-height: 300px;
  width: 100%;
  object-fit: contain;
  border: 1px solid rgb(204, 141, 219);
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 5px 1px rgb(247, 247, 247);
  border-radius:   50px 50px 50px 50px;

}

.card {
  border: none;
  box-shadow: 5px 5px 15px 3px gray;
}

.card:hover {
  box-shadow: 5px 5px 15px 3px black;
}

.card-body {
  color: black;
  text-decoration: none;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

#step2 {
  display: none;
}

.my-card {
  /* max-height: 400px; */
  width: 80%;
  margin-bottom: 10vw;
  background: transparent;
  border: 8px solid white;
  border-radius:   50px 50px 50px 50px;
}

.my-title {
  width: 80%;
  color: white;
  font-weight: 900;
}

.my-description {
  width: 80%;
  color: white;
  font-weight: 50;
}
.my-price {
  width: 80%;
  color: white;
}

.my-modal {
  width: 100%;
}
.ref-added-msg {
  font-size: 14px;
  opacity: 0.65;
  color: red;
}

@media screen and (max-width: 550px) {
  .my-title {
    font-size: 6vw;
    font-weight: 900;
  }

  .my-description {
    font-size: 5vw;
  }
}
.modal {
 
background-color:#7845af;
  border: none;
}

.headimg__modal {
  width: 2vw;
}

@media screen and (max-width: 750px) {
  .headimg__modal {
    width: 5vw;
  }
  .img__1{
    width: 80%;
  }
 
}


.my__btn{
  width: 50%;
}

.card-inside{
  background-color: #60289C;
  border: 25px solid #cc96fed7;
  border-radius: 50px 50px 50px 50px;

}


