.card__main {
  width: 50%;
}

.header__main {
  background-image: linear-gradient(-135deg, #c850c0, #4158d0);
}

@media screen and (max-width: 700px) {
  .card__main {
    width: 100%;
  }
}
